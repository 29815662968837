import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { ContractAi } from '../contract/ContractAi';
import './styles/AppMain.scss';

export const AppMain: React.FC = () => {
  const { t } = useTranslation();
  const { authState, authService } = useOktaAuth();
  const login = async () => {
    authService.login('/');
  };

  const renderAuthenticationState = () => {
    if (authState.isAuthenticated) {
      return <ContractAi />;
    }
    if (!authState.isAuthenticated) {
      return (
        <div className="app-upload-area">
          <p>{t('Contract_Not_Logged_Info')}</p>
          <a onClick={login} className="ci-header-logout" href="#Login">
            <span>{t('Login')}</span>
            <img
              src={process.env.PUBLIC_URL + '/images/login.svg'}
              alt="Login"
            />
          </a>
        </div>
      );
    }
    if (authState.isPending) {
      return (
        <div className="app-upload-area">
          <p>{t('Contract_Login_In_Progress')}</p>
        </div>
      );
    }
  };

  return (
    <main className="app-main">
      <div className="ci-page-wrapper">
        <div className="ci-module-inner">
          <h2 className="ci-module-title">Contract AI</h2>
          <p className="ci-app-main-subtitle">{t('main.info_text')}</p>
          {renderAuthenticationState()}
        </div>
      </div>
    </main>
  );
};
