import * as React from 'react';
import { Helmet } from 'react-helmet';
import i18n from '../i18n';

// Page elements
import { AppHeader } from '../components/layout/AppHeader';
import { AppMain } from '../components/layout/AppMain';
import { HowItWorks } from '../components/layout/HowItWorks';
import { About } from '../components/layout/About';
import { BertelsmannCollaborationPlatform } from '../components/layout/BertelsmannCollaborationPlatform';
import AppFooter from '../components/layout/AppFooter';

/**
 * Home page
 * SPA
 */
const Home: React.FC = () => {
  const [currentLang, setLanguage] = React.useState(
    i18n.language || window.localStorage.i18nextLng
  );

  return (
    <>
      <Helmet>
        <title>Contract AI | BERTELSMANN</title>
      </Helmet>
      <div className="App app-home">
        <AppHeader currentLang={currentLang} setLanguage={setLanguage} />
        <AppMain />
        <HowItWorks />
        <About />
        <BertelsmannCollaborationPlatform />
        <AppFooter currentLang={currentLang} />
      </div>
    </>
  );
};

export default Home;
