import filenamify from 'filenamify';
import {BlockBlobClient} from '@azure/storage-blob';
import {
    getClassificationUrl,
    getClassificationRequestOpts,
    getBlockBlobClientUrl,
    getClassificationStatusUrl,
    getApiAuthHeader,
} from '../service/contract-ai-api';
import {contractAi} from '../config/contract-ai';

export const onFileDrop = (acceptedFiles: any) => {
    return new Promise((resolve, reject) => {
        const fineName = filenamify(acceptedFiles[0].name);

        // 1. Start a classification
        fetch(getClassificationUrl(), getClassificationRequestOpts(fineName))
            .then((response) => response.json())
            .then((result: IClassificationResponse) => {
                const data: IClassificationData = result.data;
                const bbClient = new BlockBlobClient(getBlockBlobClientUrl(data));

                // 2. Upload your IT-Contract document
                bbClient
                    .uploadBrowserData(acceptedFiles[0], {
                        maxSingleShotSize: 4 * 1024 * 1024,
                    })
                    .then((result) => {
                        // 3. Check repeatedly the classification status
                        const classificationStatusUrl = getClassificationStatusUrl(
                            data.guid
                        );
                        const expiredTimeOut = contractAi.expiredTimeOut;
                        const checkInterval = contractAi.checkInterval;
                        let in_progress = false;
                        let missed_request = false;
                        let tick: NodeJS.Timeout;
                        let timeOut: NodeJS.Timeout;
                        let classificationDataCount = 0;

                        // A request interval of 1 to 3 seconds is recommended
                        const getClassificationStatus = async () => {

                            if (in_progress) {
                                missed_request = true;
                                return;
                            }

                            in_progress = true;
                            try {
                                const response = await fetch(classificationStatusUrl, {
                                    headers: getApiAuthHeader(),
                                });

                                if (missed_request) {
                                    missed_request = false;
                                    setTimeout(getClassificationStatus, 0);
                                }

                                if (response.status === 200) {
                                    const classifications = await response.json();

                                    if (classificationDataCount === 0) {
                                        // set on first call
                                        classificationDataCount = classifications.data.length;
                                    }

                                    // check status: wait for 2-done or 3-error
                                    const classificationData = classifications.data.filter(
                                        (classification: IClassification) =>
                                            classification.status > 1
                                    );

                                    // all done?
                                    if (classificationData.length === classificationDataCount) {
                                        clearInterval(tick);
                                        if (timeOut) {
                                            clearTimeout(timeOut);
                                        }

                                        resolve(classificationData);
                                    }
                                } else {
                                    clearInterval(tick);
                                    reject(response);
                                }
                            } catch (error) {
                                // catches errors both in fetch and response.json
                                clearInterval(tick);
                                reject(error);
                            } finally {
                                in_progress = false;
                            }
                        };

                        // start classification progress
                        tick = setInterval(getClassificationStatus, checkInterval);

                        // set classification timeout
                        timeOut = setTimeout(() => {
                            clearInterval(tick);
                            reject(`Classification check time ${expiredTimeOut}s has expired.`);
                        }, expiredTimeOut);

                    })
                    .catch(reject);
            })
            .catch((error) => {
                reject(error);
            });
    });
};



