// ASY Contract AI API config
export const contractAi: IContractAi = {
  apiUrl: process.env.REACT_APP_ASY_CONTRACT_API_URL
    ? process.env.REACT_APP_ASY_CONTRACT_API_URL
    : 'apiUrl.undefined',
  authKey: {
    name: process.env.REACT_APP_ASY_CONTRACT_API_KEY_NAME
      ? process.env.REACT_APP_ASY_CONTRACT_API_KEY_NAME
      : 'authKey.name.undefined',
    value: process.env.REACT_APP_ASY_CONTRACT_API_KEY_VALUE
      ? process.env.REACT_APP_ASY_CONTRACT_API_KEY_VALUE
      : 'authKey.value.undefined',
  },
  checkInterval: process.env.REACT_APP_CHECK_INTERVAL ? parseFloat(process.env.REACT_APP_CHECK_INTERVAL) : 5,
  expiredTimeOut: process.env.REACT_APP_EXPIRED_TIMEOUT ? parseFloat(process.env.REACT_APP_EXPIRED_TIMEOUT) : 5,
  acceptFileTypes: process.env.REACT_APP_ACCEPT_FILES ? process.env.REACT_APP_ACCEPT_FILES : 'text/plain,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
