import * as React from 'react';
import {useTranslation} from 'react-i18next';

export const Download: React.FC<IClassificationItems> = ({data}) => {
    const {t} = useTranslation();
    const onButtonClick = (event: React.MouseEvent) => {
        if (event) {
            event.preventDefault();
        }

        if (href) {
            const win = window.open(href, '_blank');
            return win ? win.focus() : null;
        }
    };
    let href = '';

    if (data === null) {
        return data;
    }

    for (let i=0; i < data.length; i++) {
        if (data[i].status === 2 && data[i].classificationDocument) {
            href = data[i].classificationDocument;
            break;
        }
    }

    if (href.length === 0) {
        return null;
    }

    return (
        <div className="app-classification-download">
            <button className="ci-button-primary app-button" onClick={onButtonClick}>
                {t('download.file')}
            </button>
        </div>
    );
};
