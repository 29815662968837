import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles/AppFooter.scss';

export interface IAppFooter extends React.Props<IAppFooter> {
  currentLang: string;
}

/**
 * Application footer
 */
const AppFooter: React.FC<IAppFooter> = ({ currentLang }) => {
  const { t } = useTranslation();
  const termsConditionsUrl =
    currentLang === 'de'
      ? 'https://www.arvato-systems.de/arvato-systems/impressum'
      : 'https://www.arvato-systems.com/arvato-systems-en/imprint';
  const privacyPolicyUrl =
    currentLang === 'de'
      ? 'https://www.arvato-systems.de/arvato-systems/datenschutz'
      : 'https://www.arvato-systems.com/arvato-systems-en/privacy-policy';

  return (
    <div className="ci-page-wrapper">
      <footer className="app-footer">
        <div className="ci-footer-copyright">
          <p>
            © {new Date().getFullYear()} Contract AI |  Bertelsmann.{' '}
            {t('All_rights_reserved')}
          </p>
        </div>
        <div>
          <nav>
            <ul className="app-nav">
              <li>
                <a
                  href={termsConditionsUrl}
                  title={t('Terms_Conditions')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Terms_Conditions')}
                </a>
              </li>
              <li>
                <a
                  href={privacyPolicyUrl}
                  title={t('Privacy_Policy')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Privacy_Policy')}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  );
};

export default AppFooter;
