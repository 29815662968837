import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {getReadableFileSizeString} from '../../util/formatter';

export interface IAcceptedFileInfo {
    isDragActive: boolean;
}

export const AcceptedFileInfo: React.FC<IAcceptedFileInfo> = ({isDragActive}) => {
    const {t} = useTranslation();

    return (
        <>
            <button className="ci-button-primary app-button --upload">
                {t('main.select-file.button')}
                <img
                    src={process.env.PUBLIC_URL + '/images/plus_white.svg'}
                    alt={t('main.select-file.button')}
                />
            </button>

            {isDragActive ? (
                <p>{t('main.drag.active.info')}</p>
            ) : (
                <p>{t('main.drag.inactive.info')}</p>
            )}

            <div className="ci-fileformat-hint">{t('main.fileformat.info')}</div>
        </>
    );
};

export interface ISelectedFileInfo {
    selectedFile: any,
    isProgress: boolean
}

export const SelectedFileInfo: React.FC<ISelectedFileInfo> = ({selectedFile, isProgress}) => {
    const {t} = useTranslation();
    const {name, size} = selectedFile;

    return (
        <>
            {!isProgress ?
                <img width="42px" src={`${process.env.PUBLIC_URL}/images/icon_dokument_doc.svg`} alt=""/> : ''}
            <p>
                {t('file')} "{name}" ({getReadableFileSizeString(size)})
            </p>
        </>
    );
};
