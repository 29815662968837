import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Beforeunload } from "react-beforeunload";
import "react-toastify/dist/ReactToastify.css";

import { onFileDrop } from "../../util/on-file-drop";
import { AcceptedFileInfo, SelectedFileInfo } from "./FileInfo";
import { Classification } from "./Classification";
import { Download } from "./Download";
import { contractAi } from "../../config/contract-ai";

import "./styles/Uploader.scss";

export const ContractAi: React.FC = () => {
  const [isFileSelected, setSelectedFile] = React.useState(false);
  const [selectedFile, setFile] = React.useState(undefined);
  const [isProgress, setProgress] = React.useState(false);
  const [classificationData, setClassificationData] = React.useState([]);
  const { t } = useTranslation();
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setSelectedFile(true);
      setProgress(true);

      // classification
      onFileDrop(acceptedFiles)
        .catch((error: any) => {
          const errMsg = t("classification.error") + ": " + error.toString();
          console.error(error);
          setSelectedFile(false);
          setProgress(false);

          toast(errMsg);
        })
        .then((data: any) => {
          const sortedData = reorderResult(data);
          setSelectedFile(false);
          setProgress(false);
          setProgress(false);
          setClassificationData(sortedData);
        });
    },
    [t]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const reload = () => {
    setSelectedFile(false);
    setFile(undefined);
    setClassificationData([]);
  };
  const statusIcon = isProgress ? "/images/Analyse.svg" : "/images/Upload.svg";
  const statusClassName = isProgress ? "progress" : "";

  if (classificationData && classificationData.length) {
    return (
      <>
        <Beforeunload onBeforeunload={() => "You'll lose your data!"}>
          <div className="app-classification-area">
            <div className="app-classification-area-inner">
              <h3>{t("classification.success.title")}</h3>
              <SelectedFileInfo
                selectedFile={selectedFile}
                isProgress={isProgress}
              />
              <Classification data={classificationData} />
              <Download data={classificationData} />
            </div>
          </div>
          <br />
          <br />
          <div className="app-upload-area">
            <div className="app-upload-area-inner">
              <button
                className="ci-button-primary app-button reload"
                onClick={() => {
                  reload();
                  // back to top
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
              >
                {t("download.file.reload")}
              </button>
            </div>
          </div>
        </Beforeunload>
      </>
    );
  } else {
    return (
      <div className="app-upload-area" {...getRootProps()}>
        <div className="app-upload-area-inner">
          <div className={`ci-upload-indicator ${statusClassName}`}>
            <img
              src={process.env.PUBLIC_URL + statusIcon}
              alt={t("main.select-file.button")}
            />
          </div>
          <input
            {...getInputProps({
              disabled: isFileSelected,
              multiple: false,
              accept: contractAi.acceptFileTypes,
            })}
          />
          {isFileSelected ? (
            <SelectedFileInfo
              selectedFile={selectedFile}
              isProgress={isProgress}
            />
          ) : (
            <AcceptedFileInfo isDragActive={isDragActive} />
          )}
        </div>
      </div>
    );
  }
};

const reorderResult = (result: any) => {
  const newResult = [];
  newResult.push(result[9]); // grobe Fahrlaessigkeit
  newResult.push(result[4]); // einfache Fahrlaessigkeit
  newResult.push(result[5]); // entgangene Gewinn
  newResult.push(result[13]); // verjaehrung
  newResult.push(result[2]); // change of control
  newResult.push(result[17]); // zahlungsziel
  newResult.push(result[3]); // datenschutz
  newResult.push(result[16]); // verzugszinsen
  newResult.push(result[12]); // nutzungsrecht
  newResult.push(result[10]); // kompensation bei kuendigung
  newResult.push(result[1]); // buergschaft
  newResult.push(result[6]); // exklusivitaet
  newResult.push(result[8]); // garantie
  newResult.push(result[15]); // vertragsstrafe
  newResult.push(result[0]); // anwendbares recht
  newResult.push(result[7]); // freistellung
  newResult.push(result[14]); // versicherung
  newResult.push(result[11]); // laufzeit & kuendigung

  return newResult as any;
};
