import * as React from 'react';
import slugify from "slugify";
import {ClassificationItem} from './ClassificationItem';

export const Classification: React.FC<IClassificationItems> = ({data}) => {
    if (data === null) {
        return data;
    }

    return (
        <div className="app-classification">
            <dl id="toggle">
                {data.map((item: IClassification, index: number) =>
                    <ClassificationItem key={slugify(item.classificationHeader, {lower: true})} idx={index} item={item}/>)}
            </dl>
        </div>
    );
};
