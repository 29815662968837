import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const NotFound = (props: { location: any }) => (
  <div className="App app-404">
    <Helmet>
      <title>404! | Contract AI | BERTELSMANN</title>
    </Helmet>
    <h3>Error 404 - page not found!</h3>
    <p>
      We are sorry but the page you are looking for "
      <code>{props.location.pathname}</code>" does not exist.
    </p>
    <p>
      <Link to="/">Home</Link>
    </p>
  </div>
);

export default NotFound;
