const baseUrl = process.env.REACT_APP_OKTA_BASE_URL
  ? process.env.REACT_APP_OKTA_BASE_URL
  : 'https://okta.baseUrl.undefined.dev';
const serverId = process.env.REACT_APP_OKTA_SERVER_ID
    ? process.env.REACT_APP_OKTA_SERVER_ID
    : 'okta.serverId.undefined';
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID
  ? process.env.REACT_APP_OKTA_CLIENT_ID
  : 'okta.clientId.undefined';
const scopes = process.env.REACT_APP_OKTA_SCOPES
    ? process.env.REACT_APP_OKTA_SCOPES.split(',')
    : ["openid:undefined"];

/**
 * OKTA oAuth Settings
 * Common properties are not controlled by .env (are equal)
 */
export const OKTA_CONFIG = {
  oidc: {
    baseUrl: baseUrl,
    clientId: clientId,
    redirectUri: document.location.origin + '/implicit/callback',
    authParams: {
      issuer: `${baseUrl}/oauth2/${serverId}`,
      authorizeUrl: `${baseUrl}/oauth2/${serverId}/v1/authorize`,
      scopes: scopes,
      pkce: true,
      display: 'page',
      responseMode: 'query',
      responseType: ['code'],
    },
  },
};
