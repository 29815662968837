import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

export const Author: React.FC = () => {
  const { t } = useTranslation();
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = React.useState(null);

  React.useEffect(() => {
    if (!authState.isAuthenticated) {
      // PKCE Code Verification Failure https://github.com/okta/okta-oidc-js/issues/804
      sessionStorage.setItem('okta-pkce-storage', '{}');
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      authService.getUser().then((info: any) => {
        setUserInfo(info);
      });
    }
  }, [authState, authService]); // Update if authState changes

  const login = async (e: React.MouseEvent) => {
    e.preventDefault();
    authService.login('/');
  };

  const logout = async (e: React.MouseEvent) => {
    e.preventDefault();
    authService.logout('/');
  };

  const renderAuthenticationState = () => {
    if (authState.isPending) {
      return <span>{t('Signing-in')}</span>;
    }

    if (authState.isAuthenticated && !userInfo) {
      return <span>{t('Loading-user-info')}</span>;
    }
    if (authState.isAuthenticated && userInfo) {
      return (
        <a
          href="#logout"
          onClick={logout}
          className="ci-header-logout"
          title={t('Signing-out')}
        >
          <span>{t('Logout')}</span>{' '}
          <img
            src={process.env.PUBLIC_URL + '/images/logout.svg'}
            alt={t('Logout')}
          />
        </a>
      );
    }
    if (!authState.isAuthenticated) {
      return (
        <a
          onClick={login}
          className="ci-header-logout"
          href="#Login"
          title={t('Signing-in')}
        >
          <span>{t('Login')}</span>{' '}
          <img
            src={process.env.PUBLIC_URL + '/images/login.svg'}
            alt={t('Login')}
          />
        </a>
      );
    }
  };

  return <div>{renderAuthenticationState()}</div>;
};
