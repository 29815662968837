import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { getReadablePercent } from '../../util/formatter';

export const ClassificationItem: React.FC<IClassificationItem> = ({
  idx,
  item,
}) => {
  const [isOpen, setOpen] = React.useState(true);
  const [currentHeight, setCurrentHeight] = React.useState('0px');
  const content = React.useRef<HTMLHeadingElement>(null);
  const { t } = useTranslation();
  const itemId = `item-${idx}`;
  const noContentClassName =
    item.additionalInformation === 'kein Text gefunden'
      ? 'text-not-found'
      : 'text-found';

  const onItemClick = (e: React.MouseEvent) => {
    e.preventDefault();

    setOpen(!isOpen);

    if (null !== content.current) {
      isOpen
        ? content.current.classList.add('is-open')
        : content.current.classList.remove('is-open');

      setCurrentHeight(
        isOpen ? `${content.current.scrollHeight + 42}px` : '0px'
      );
    }
  };
  const renderDetail = (item: IClassification) => {
    if (item.status === 2) {
      const details =
        item.classificationDetails &&
        item.classificationDetails.length > 0 &&
        item.classificationDetails[0] !== 'NaN'
          ? item.classificationDetails.join(', ')
          : null;
      const pageNo = isNaN(parseInt(item.pageNumber))
        ? null
        : `${t('pageNumber')} ${item.pageNumber}`;
      const paragraphNo =
        item.paragraphNumber === 'NaN' ? null : `\xA7 ${item.paragraphNumber}`;

      return (
        <>
          <header className="app-classification-header">
            <h4>
              {item.classificationText} {details}
            </h4>
            <h5>{pageNo}</h5>
            <h5>{paragraphNo}</h5>
          </header>
          <div className="app-classification-body">
            <p className="app-classification-additional-information">
              {item.additionalInformation}
            </p>
            <p className="app-classification-precision">
              {t('Precision')}:{' '}
              <strong>{getReadablePercent(item.precision)}</strong>
            </p>
          </div>
        </>
      );
    } else {
      return (
        <header className="app-classification-header error">
          <h4>{t('classification.No-result-available')}</h4>
        </header>
      );
    }
  };

  return (
    <>
      <dt
        className={`app-classification-item-title ${itemId} ${noContentClassName}`}
        onClick={onItemClick}
      >
        <h3>{item.classificationHeader}</h3>
        <a
          href={`#${itemId}`}
          className={
            'app-classification-item-toggle ' + (isOpen ? 'opened' : 'closed')
          }
        >
          <img
            alt="toggle"
            src={process.env.PUBLIC_URL + '/images/chevron_down.svg'}
          />
        </a>
      </dt>
      <dd
        className="app-classification-item-content"
        id={`item-${idx}`}
        ref={content}
        style={{ maxHeight: `${currentHeight}` }}
      >
        {renderDetail(item)}
      </dd>
    </>
  );
};
