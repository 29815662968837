/**
 * File size formatter
 * @param fileSizeInBytes
 */
export const getReadableFileSizeString = (fileSizeInBytes: number): string => {
  let i = -1;
  const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes = fileSizeInBytes / 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

/**
 * Readable percent formatter
 * Default minimum fraction digits is 2
 * @param {number} precision
 * @param {int} minimumFractionDigits
 */
export const getReadablePercent = (precision: number, minimumFractionDigits?: 2): string => Number(precision).toLocaleString(undefined, {
  style: 'percent',
  minimumFractionDigits: minimumFractionDigits
})
