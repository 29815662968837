import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { Security, LoginCallback } from '@okta/okta-react';
import { OKTA_CONFIG } from './config/okta';
import Login from './container/Login';
import Home from './container/Home';
import NotFound from './container/NotFound';
import './App.scss';

function loading() {
  return <div className="app-loading">Loading...</div>;
}

const HasAccessToRouter = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push('/login');
  };

  const { clientId, redirectUri } = OKTA_CONFIG.oidc;
  const { issuer, scopes, pkce, authorizeUrl } = OKTA_CONFIG.oidc.authParams;

  return (
    <Security
      clientId={clientId}
      redirectUri={redirectUri}
      issuer={issuer}
      authorizeUrl={authorizeUrl}
      scopes={scopes}
      pkce={pkce}
      onAuthRequired={customAuthHandler}
    >
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/implicit/callback" component={LoginCallback} />
        <Route path="/login" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Security>
  );
};

function App() {
  return (
    <Suspense fallback={loading()}>
      <div>
        <Router>
          <HasAccessToRouter />
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
