import * as React from 'react';
import Dropdown from 'react-dropdown';
import i18n from '../../i18n';
import 'react-dropdown/style.css';
import './styles/LanguageChooser.scss';

export interface ILanguageChooser extends React.Props<ILanguageChooser> {
  setLanguage: any;
  currentLang: string;
}

const LanguageChooser: React.FC<ILanguageChooser> = ({
  setLanguage,
  currentLang,
}) => {
  const changeLanguage = (lng: string): void => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };
  const options = [
    { value: 'de', label: 'DE' },
    { value: 'en', label: 'EN' },
  ];
  const defaultOption = currentLang === 'de' ? options[0] : options[1];

  return (
    <div className="app-language-chooser">
      <Dropdown
        options={options}
        value={defaultOption}
        onChange={(option) => changeLanguage(option.value)}
      />
    </div>
  );
};

export default LanguageChooser;
