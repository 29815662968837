import React, { Fragment } from 'react';
import { Card, ICard, CardSpacer } from '../common';
import { useTranslation } from 'react-i18next';
import './styles/HowItWorks.scss';

export const HowItWorks = () => {
  const { t } = useTranslation();
  const cards: ICard[] = [
    {
      imgAlt: t('HowItWorks.Upload.title'),
      imgSrc: 'Upload.svg',
      title: t('HowItWorks.Upload.title'),
      text: t('HowItWorks.Upload.text'),
    },
    {
      imgAlt: t('HowItWorks.Analyse.title'),
      imgSrc: 'Analyse.svg',
      title: t('HowItWorks.Analyse.title'),
      text: t('HowItWorks.Analyse.text'),
    },
    {
      imgAlt: t('HowItWorks.Ergebnis.title'),
      imgSrc: 'Ergebnis.svg',
      title: t('HowItWorks.Ergebnis.title'),
      text: t('HowItWorks.Ergebnis.text'),
    },
  ];

  return (
    <section className="ci-page-wrapper ci-module-spacer">
      <h2 className="ci-module-title">{t('howItWorks.title')}</h2>

      <div className="app-how-it-works-list">
        {cards.map(function (card: ICard, i: number) {
          return (
            <Fragment key={`item-${i}`}>
              <Card {...card} key={`card-${i}`} />
              <CardSpacer key={`card-spacer-${i}`} />
            </Fragment>
          );
        })}
      </div>
    </section>
  );
};
