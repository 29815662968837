import {contractAi} from '../config/contract-ai';

export const getClassificationUrl = (): string => {
    return `${contractAi.apiUrl}/contracts`;
};

export const getClassificationRequestOpts = (fileName: string): object => {
    return {
        body: JSON.stringify({
            documentName: fileName,
        }),
        method: 'POST',
        redirect: 'follow',
        headers: getApiAuthHeader(),
    };
};

/**
 * Request URL for Upload your IT-Contract document
 * @param classificationData    file response data
 */
export const getBlockBlobClientUrl = (
    classificationData: IClassificationData
): string => {
    let url = classificationData.uri;
    //url = url.replace('https://bercaidevsta000.blob.core.windows.net', 'http://localhost:3000/blob');

    return `${url}/${classificationData.documentName}${classificationData.token}`;
};

export const getClassificationStatusUrl = (fileId: string): string => {
    return `${contractAi.apiUrl}/contracts/${fileId}`;
};

export const getApiAuthHeader = (): Headers => {
    const headers = new Headers();

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.set(contractAi.authKey.name, contractAi.authKey.value);

    return headers;
};
