import * as React from 'react';
import { useTranslation } from 'react-i18next';
import './styles/About.scss';

/**
 * Module: about
 */
export const About: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="ci-page-wrapper ci-module-spacer">
      <h2 className="ci-module-title">{t('about.title')}</h2>
      <p className="ci-module-inner ci-about-info">{t('about.info')}</p>
    </section>
  );
};
