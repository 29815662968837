import * as React from 'react';

export interface ICard extends React.Props<ICard> {
  imgAlt: string;
  imgSrc: string;
  title: string;
  text: string;
}

export const Card: React.FC<ICard> = ({ imgSrc, imgAlt, title, text }) => {
  return (
    <div className="ci-how-it-works-item">
      <img src={process.env.PUBLIC_URL + '/images/' + imgSrc} alt={imgAlt} />
      <div>
        <h4 className="ci-step-title">{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};
