import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import { OKTA_CONFIG } from '../config/okta';
import i18n from '../i18n';

const OktaSignIn = require('@okta/okta-signin-widget');

/**
 * Login Page
 * Using the OKTA signin widget
 */
const Login: React.FC = () => {
  const widgetRef = React.useRef(null);
  const [rendered, setRendered] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    const { baseUrl, clientId, redirectUri } = OKTA_CONFIG.oidc;
    const {
      pkce,
      issuer,
      scopes,
      display,
      responseMode,
      responseType,
    } = OKTA_CONFIG.oidc.authParams;
    const widget = new OktaSignIn({
      baseUrl,
      clientId,
      redirectUri,
      assets: {
        baseUrl: '/',
      },
      logo: '/images/logo_Bertelsmann.svg',
      language: i18n.language,
      authParams: {
        pkce,
        issuer,
        display,
        responseMode,
        responseType,
        scopes,
      },
      customButtons: [
        {
          title: t('Abort'),
          className: 'btn-customAuth',
          click: function () {
            window.location.href = '/';
          },
        },
      ],
    });

    if (!rendered) {
      widget.renderEl({
        el: widgetRef.current,
      });
      setRendered(true);
    }
  }, [t, widgetRef, rendered, setRendered]);

  return (
    <>
      <Helmet>
        <title>Login | Contract AI | BERTELSMANN</title>
      </Helmet>
      <div className="App app-login">
        <div ref={widgetRef} />
      </div>
    </>
  );
};

export default Login;
