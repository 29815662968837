import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './styles/BertelsmannCollaborationPlatform.scss';

/**
 * Module: Bertelsmann collaboration platform info
 */
export const BertelsmannCollaborationPlatform: React.FC = () => {
  const { t } = useTranslation();
  return (
    <section className="ci-page-wrapper">
      <div className="app-box-grey">
        <div>
          <img
            src={process.env.PUBLIC_URL + '/images/collaboration-plattform.png'}
            alt="Join us"
          />
        </div>
        <div className="text-align-left ci-info-container">
          <h3 className="ci-title">{t('collaboration.title')}</h3>
          <p className="ci-info">{t('collaboration.text')}</p>
          <a
            className="ci-button-primary"
            href="https://developers.bertelsmann.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('collaboration.find_out_more')}
            <img
              src={process.env.PUBLIC_URL + '/images/chevron_right_white.svg'}
              alt={t('collaboration.find_out_more')}
            />
          </a>
        </div>
      </div>
    </section>
  );
};
