import * as React from 'react';
import { Author } from '../Author';
import LanguageChooser from '../common/LanguageChooser';
import './styles/AppHeader.scss';

export interface IAppHeader extends React.Props<IAppHeader> {
  currentLang: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Application header
 */
export const AppHeader: React.FC<IAppHeader> = ({
  currentLang,
  setLanguage,
}) => {
  return (
    <div className="ci-page-wrapper">
      <header className="app-header">
        <div className="app-header-logos">
          <div className="app-header-logo-bertelsmann">
            <img
              className="logo-full"
              src={process.env.PUBLIC_URL + '/images/logo_Bertelsmann.svg'}
              alt="Bertelsmann"
            />
            <img
              className="logo-short"
              src={process.env.PUBLIC_URL + '/images/logo_Bertelsmann_B.svg'}
              alt="Bertelsmann"
            />
          </div>
          <div className="app-header-name">
            <img
              src={process.env.PUBLIC_URL + '/images/logo_contract_ai.svg'}
              alt="Contract AI"
            />
            Contract AI
          </div>
        </div>
        <div className="app-auth-language">
          <Author />
          <LanguageChooser
            currentLang={currentLang}
            setLanguage={setLanguage}
          />
        </div>
      </header>
    </div>
  );
};
